import router from '@/router/index';
import { getCookie } from '@/utils/cookie';
import Vue from 'vue';
import { format } from '@/utils/date';
import store from '@/store/index';

async function showOldSystemDateDialog(
  searchData: any,
  initModule?: 'OUTBOUND' | 'INBOUND' | 'INVENTORY'
) {
  let module = '';
  if (initModule) {
    module = initModule;
  } else {
    if (
      ['inbound', 'moveinbound', 'returninbound', 'reportcenter'].some((x) =>
        (router as any).history.current.path.includes(x)
      )
    ) {
      module = 'INBOUND';
    } else if (
      ['salesoutbound', 'rtsoutbound', 'mtoutbound'].some((x) =>
        (router as any).history.current.path.includes(x)
      )
    ) {
      module = 'OUTBOUND';
    } else if (
      ['inventorymanage', 'inventorymap'].some((x) =>
        (router as any).history.current.path.includes(x)
      )
    ) {
      module = 'INVENTORY';
    }
  }
  const warehouse = getCookie('setting_whs_v2') || '';

  if (!(store as any).state.warehouseSwitchTime.warehouseSwitchTime[module]) {
    return;
  }

  const warehouseChangeTimestamp =
    (store as any).state.warehouseSwitchTime.warehouseSwitchTime[module][
      warehouse
    ] || 0;
  const searchDataValueList = Object.values(searchData);
  for (const item of searchDataValueList) {
    const regExp = /^1\d{9}$/;

    if (typeof item !== 'number' || String(item as any).length !== 10) {
      return;
    }

    if (regExp.test(item as any) && (item as any) < warehouseChangeTimestamp) {
      Vue.prototype
        .$msgbox({
          title: 'Notice',
          message: `Date before ${format(
            warehouseChangeTimestamp,
            'day'
          )} is in old system. Please proceed to old WMS to retrieve data.`,
          showCancelButton: false,
        })
        .then((res: any) => {
          console.log(res);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }
}

export default showOldSystemDateDialog;
