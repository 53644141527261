export const typeDic = {
  'datetime': 1,
  'datetimerange': 2,
  'daterange': 2,
  ['big-input']: 2,
  ['big-input-group']: 2,
  ['big-input-number-group']: 2,
  'input': 1,
  'select': 1,
  ['input-cascader']: 1,
  ['big-select']: 2,
  '3pl-select': 1,
  ['3pl-big-select']: 2,
  ['input-number']: 1,
  ['input-number-clearable']: 1,
  'inputrange': 2,
  'inputnumberrange': 2,
  ['text-card']: 1,
  ['select-card']: 1,
  ['big-text-card']: 2,
  ['big-select-card']: 2,
  ['big-input-cascader']: 2,
  ['small-input-number-range']: 1,
};

export const noNeedTooltip = ['Pending Owner Transfer'];
export const inputGroupType = ['big-input-group', 'big-input-number-group'];
