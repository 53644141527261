import * as core from '@scfe-common/core';
import { getCookie } from '@/utils/cookie';
import { getEnv } from '@/utils/getEnv';
// import router from '../router/index';
// import routesConfig from '../router/config';
import { getPlatform } from '@/utils/getCountry';
import { once } from 'lodash';
import ping from './ping';

// QMS\DAS
const {
  report: {
    Reporter,
    error: { JsError },
    count: { PV, Click, Numeric },
    api: { API, SlaApi },
    speed: { Perf },
  },
  storage: { cookie },
  // utils: { getRouteRegister },
} = core;

const getCountry = function () {
  // const country = localStorage.getItem('country');
  // return country ? country.split('-')[0].trim() : '';
  const country = getCookie('setting_whs_v2') || '';
  return country.slice(0, 2);
};

const reportURL =
  getEnv() === 'live'
    ? 'https://autobahn.ssc.shopeemobile.com/data/'
    : 'https://autobahn.ssc.test.shopeemobile.com/data/';

export const reporter = new Reporter(
  reportURL,
  {
    bt: 'SBS',
    sbt: 'WMSV2',
    env: getEnv(),
    uid: cookie.get('wms_display_name') || '',
    // ut: 'normal',
    ut: getCookie('setting_whs_v2') || '', //记录仓库
    sid: core.user.session.get() || '',
    a: getCountry() || '',
    pf: getPlatform(),
  },
  // debounce time = 3s
  3000
);

const clickWatcher = getEnv() !== 'dev' ? new Click(reporter, 1) : null;

export const reportEvent = function (eventName: string, data: any = {}) {
  if (clickWatcher && typeof eventName === 'string') {
    clickWatcher.collect({
      d: {
        type: 'click',
        key: eventName,
        data,
      },
      dt: 'count',
      s: location.href,
    });
  }
};

const translateTracker = getEnv() !== 'dev' ? new Numeric(reporter, 100) : null;

export const reportTransition = function (data: any = {}) {
  if (translateTracker) {
    translateTracker.collect({
      d: {
        type: 'exposure',
        key: 'page_translate_data',
        data,
      },
      dt: 'count',
      s: location.href,
    });
  }
};

export const numericTracker =
  getEnv() !== 'dev' ? new Numeric(reporter, 1) : null;

export const reportToastTransition = function (data: any = {}) {
  if (numericTracker) {
    numericTracker.collect({
      d: {
        type: 'exposure',
        key: 'toast_translate_data',
        data,
      },
      dt: 'count',
      s: location.href,
    });
  }
};

interface NumericMoreData {
  trace_id?: string;
  rate?: number;
}

export class ReportDwellTime {
  tId: string | undefined;

  constructor(name: string) {
    numericTracker && (this.tId = numericTracker.start(name));
  }

  abort() {
    this.tId && numericTracker?.abort(this.tId);
  }

  end(moreData?: NumericMoreData) {
    this.tId && numericTracker?.end(this.tId, moreData);
  }
}

export const APIWatcher = (axiosInstance: any) => {
  if (getEnv() === 'dev') {
    return;
  }
  return new API(
    reporter,
    1,
    {
      apiPrefix: '/api',
      registerPaths: [],
      onBeforeReport: (data: any, config: any, response: any) => {
        // 重复请求取消上报
        if (config.cancelToken) return false;
        return {
          trace_id: response?.headers?.trace_id || '',
          version: process.env.VUE_APP_VERSION + '',
        };
      },
      TimerMaxDuration: 12000,
    },
    axiosInstance
  );
};

export const SLAWatcher = (axiosInstance: any) => {
  //
  if (getEnv() === 'dev') {
    return;
  }
  return new SlaApi(
    reporter,
    {
      useAsyncSlaConfig: true,
      // slaApiConfig: {
      //   /**
      //    * collect doc
      //    * https://docs.google.com/spreadsheets/d/1S5qfex2keI9fTYy1FFZYkoKrOKHAQ4jCwZLcp_svod4/edit#gid=684487457
      //    */

      //   // Sales OutBound - Picking
      //   '/api/v2/apps/process/outbound/picking/get_task_detail': [],
      //   '/api/v2/apps/process/outbound/picking/validate_picking_location': [],
      //   '/api/v2/apps/process/outbound/picking/check_scan_sku': [],
      //   '/api/v2/apps/process/outbound/picking/scan_record': [],
      //   '/api/v2/apps/process/outbound/picking/complete_picking': [],

      //   // Sales OutBound - Sorting
      //   '/api/v2/apps/process/outbound/sorting/scan_sorting_station_id': [],
      //   '/api/v2/apps/process/outbound/sorting/scan_picking_id': [],
      //   '/api/v2/apps/process/outbound/sorting/scan_user_id': [],
      //   '/api/v2/apps/process/outbound/sorting/get_possible_sku_list': [],
      //   '/api/v2/apps/process/outbound/sorting/get_sorted_list': [],
      //   '/api/v2/apps/process/outbound/sorting/get_unsorted_list': [],
      //   '/api/v2/apps/process/outbound/sorting/complete_sorting_task': [],

      //   // Sales OutBound - Checking
      //   '/api/v2/apps/process/outbound/checking/bind_station': [],
      //   '/api/v2/apps/process/outbound/checking/search_checking_task_info': [],
      //   '/api/v2/apps/basic/sku/get_sku_by_mapping_code': [],
      //   '/api/v2/apps/process/outbound/checking/search_checking_possible_sku_list':
      //     [],
      //   '/api/v2/apps/process/outbound/checking/scan_checking_sku': [],
      //   '/api/v2/apps/process/outbound/checking/print_checking_label': [],
      //   '/api/v2/apps/process/outbound/checking/add_weight': [],
      //   '/api/v2/apps/process/outbound/checking/search_sku_checking_and_checked_list':
      //     [],
      //   '/api/v2/apps/process/outbound/checking/complete_order': [],

      //   // Sales Outbound - Packing
      //   '/api/v2/apps/process/outbound/packing/check_station': [],
      //   '/api/v2/apps/process/outbound/packing/validate_packing_user': [],
      //   '/api/v2/apps/process/outbound/packing/bind_station': [],
      //   '/api/v2/apps/process/outbound/packing/search_packing_task': [],
      //   '/api/v2/apps/process/outbound/packing/get_remain_packer_list': [],
      //   '/api/v2/apps/process/outbound/packing/create_packing_task': [],
      //   '/api/v2/apps/process/outbound/packing/complete_packing_task': [],
      //   '/api/v2/apps/process/outbound/packing/scan_packing_record': [],

      //   // Sales Outbound - Shipping
      //   '/api/v2/apps/process/outbound/shipping/validate_shipping_lm_tracking':
      //     [],
      //   '/api/v2/apps/process/outbound/shipping/get_shipping_task_detail': [],
      //   '/api/v2/apps/process/outbound/shipping/search_shipping_order': [],
      //   '/api/v2/apps/process/outbound/shipping/complete_shipping_task': [],
      //   '/api/v2/apps/process/outbound/shipping/create_shipping_order': [],
      // },
    },
    [axiosInstance]
  );
};

let pvCollector: any = null;

export function reportPV(path: string) {
  if (getEnv() === 'dev') return;
  if (!reporter) return;

  if (!pvCollector) {
    pvCollector = new PV(reporter, 1, {});
    return;
  }
  pvCollector.report(path);
}

async function initQMS() {
  if (getEnv() === 'dev') {
    return;
  }

  // DAS
  // const result = getRouteRegister(routesConfig, {
  //   pathField: 'path', // default is 'path'
  //   childField: 'children', // default is 'children'
  //   prefix: '/v2', // prefix
  //   defaultRegister: [], // defaultRegister has a higher priority
  // }); // output: ["/mobile/detail/test1/:id"]
  // const pvCollector = new PV(reporter, 1, {
  //   routePrefix: '/v2',
  //   routeRegister: result,
  // });

  // router.afterEach((to: any, from: any) => {
  //   if (from.path === '/') {
  //     return; // 忽略页面加载的路由变更，已经做了页面加载的上报
  //   }
  //   pvCollector.report();
  // });

  // 下面两行会导致页面刷新时的pv重复上报，通过data-daskey的埋点重复上报
  // new PV(reporter, 1, {});
  // new Click(reporter, 1);
  window.addEventListener(
    'error',
    function (event) {
      try {
        const target = event?.target;
        if (target instanceof HTMLImageElement) {
          if (target?.src !== (target?.baseURI ?? window.location.href)) {
            ping.ping(target?.src, (err: any, data: any) => {
              if (err) {
                reportEvent('imageLoadFail', {
                  imageSrc: target?.src ?? 'undefined',
                  complete: target?.complete ?? false,
                  pageUrl: target?.baseURI ?? window.location.href ?? '',
                });
              }
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    { capture: true }
  );
  new JsError(reporter, 1);
  new Perf(reporter, 1, { timeout: 10000 }); // Performance
}

const singleInstanceInitQMS = once(initQMS);
singleInstanceInitQMS();
