import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module
class RemoteModules extends VuexModule {
  loadedMap = {
    SPXInworkReactCCTVModule: false,
    WMSWorkforce: false,
  };

  @Mutation
  setNewLoadedModule(module: string): void {
    this.loadedMap[module] = true;
  }

  @Action({ commit: 'setNewLoadedModule' })
  async getNewLoadedModule(module: string): Promise<string> {
    return module;
  }
}
export default RemoteModules;
