import { getDevConf } from '@/api/basic/displaySetting';
import store from '@/store';
import { hasPermission } from '@/utils/checkPermission';
import { getCookie } from '@/utils/cookie';
import { tabs, Tab } from '@/views/wms-basic/storage-area-setting/tabs';
import { AsyncComponent } from 'vue';

const StorageAreaSetting = 'PC.WMSBasic.StorageAreaSetting';
const WHSPlanning = `${StorageAreaSetting}.WHSPlanning`;
const Zone = `${StorageAreaSetting}.Zone`;
/** 路由名称 */
export const RouterName = {
  name: 'wmsbasic',
  StorageAreaSetting: {
    name: 'wmsbasic.storageAreaSetting',
    WHSPlanning: {
      name: `${WHSPlanning}.View`,
      AddNewSector: {
        name: `${WHSPlanning}.AddNewSector`,
      },
    },
    Zone: {
      name: `${Zone}.View`,
      Inbound: {
        name: `${Zone}.Inbound.View`,
      },
      Outbound: {
        name: `${Zone}.Outbound.View`,
      },
      Inventory: {
        name: `${Zone}.Inventory.View`,
      },
    },
  },
};
const navList = [
  {
    routeName: RouterName.name,
    title: 'WMS Basic',
  },
  {
    routeName: RouterName.StorageAreaSetting.name,
    title: 'Storage Area Setting',
  },
];

const wmsBasic = {
  path: '/wmsbasic',
  name: 'wmsbasic',
  meta: {
    title: 'WMS Basic',
    icon: 'WMS Basic',
  },
  redirect: '/wmsbasic/warehouse',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'warehouse',
      name: 'wmsbasic.warehouse',
      meta: {
        title: 'Warehouse',
      },
      component: () => import('../../views/wms-basic/warehouse/index.vue'),
    },
    {
      path: 'warehouse/create',
      name: 'wmsbasic.warehouse.create',
      meta: {
        title: 'Create',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic.warehouse',
            title: 'Warehouse',
          },
        ],
      },
      component: () =>
        import('../../views/wms-basic/warehouse/warehouseCreateDetailEdit.vue'),
    },
    {
      path: 'warehouse/detail',
      name: 'wmsbasic.warehouse.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.warehouse',
            title: 'Warehouse',
          },
        ],
      },
      component: () =>
        import('../../views/wms-basic/warehouse/warehouseCreateDetailEdit.vue'),
    },
    {
      path: 'warehouse/edit',
      name: 'wmsbasic.warehouse.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic.warehouse',
            title: 'Warehouse',
          },
        ],
      },
      component: () =>
        import('../../views/wms-basic/warehouse/warehouseCreateDetailEdit.vue'),
    },
    {
      path: '/wmsbasic',
      name: 'wmsbasic.storageAreaSetting',
      meta: {
        title: 'Storage Area Setting',
      },
      redirect: getFirstStorageTab,
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/zone-area/storage-area-setting.vue'
        ),
    },
    {
      path: 'whsplanning',
      name: RouterName.StorageAreaSetting.WHSPlanning.name,
      meta: {
        title: 'WHS Planning',
        menuHide: true,
        navList,
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/whs-planning/whs-planning.vue'
        ),
    },
    {
      path: 'zonecluster',
      name: 'PC.WMSBasic.StorageAreaSetting.ZoneCluster.View',
      meta: {
        title: 'Zone Cluster',
        menuHide: true,
        navList,
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/zone-area/storage-area-setting.vue'
        ),
    },
    {
      path: 'park',
      name: 'PC.WMSBasic.StorageAreaSetting.Park.View',
      meta: {
        title: 'Park',
        menuHide: true,
        navList,
      },
      component: () =>
        import('../../views/wms-basic/storage-area-setting/park/park-list.vue'),
    },
    {
      path: 'inboundStagingLocation',
      name: 'PC.WMSBasic.StorageAreaSetting.InboundStagingLocation',
      meta: {
        title: 'Inbound Staging Location ',
        menuHide: true,
        navList,
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/inbound-staging-location/index.vue'
        ),
    },
    {
      path: 'outboundStagingLocation',
      name: 'PC.WMSBasic.StorageAreaSetting.OutboundStagingLocation',
      meta: {
        title: 'Outbound Staging Location',
        menuHide: true,
        navList,
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/outbound-staging-location/index.vue'
        ),
    },
    {
      path: 'rtStagingLocation',
      name: 'PC.WMSBasic.StorageAreaSetting.RTStagingLocation',
      meta: {
        title: 'RT Staging Location ',
        menuHide: true,
        navList,
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/rt-staging-location/index.vue'
        ),
    },
    {
      path: 'storey',
      name: 'PC.WMSBasic.StorageAreaSetting.Storey',
      meta: {
        title: 'Storey ',
        menuHide: true,
        navList,
      },
      component: () =>
        import('../../views/wms-basic/storage-area-setting/storey/storey.vue'),
    },
    {
      path: 'zone',
      name: RouterName.StorageAreaSetting.Zone.name,
      meta: {
        title: 'Zone',
        menuHide: true,
        navList,
      },
      component: () =>
        import('../../views/wms-basic/storage-area-setting/zone/zone.vue'),
    },
    {
      path: 'zone/create',
      name: 'wmsbasic.zone.create',
      meta: {
        title: 'Create',
        menuHide: true,
        navList: [
          ...navList,
          {
            routeName: RouterName.StorageAreaSetting.Zone.name,
            title: 'Zone',
          },
        ],
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/zone/zoneCreateEdit.vue'
        ),
    },
    {
      path: 'zone/edit',
      name: 'wmsbasic.zone.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          ...navList,
          {
            routeName: RouterName.StorageAreaSetting.Zone.name,
            title: 'Zone',
          },
        ],
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/zone/zoneCreateEdit.vue'
        ),
    },
    {
      path: 'zone/detail',
      name: 'wmsbasic.zone.detail',
      meta: {
        title: 'Detail',
        icon: '',
        menuHide: true,
        navList: [
          ...navList,
          {
            routeName: RouterName.StorageAreaSetting.Zone.name,
            title: 'Zone',
          },
        ],
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/zone/zoneCreateEdit.vue'
        ),
    },

    {
      path: 'logicArea',
      name: 'PC.WMSBasic.StorageAreaSetting.LogicArea',
      meta: {
        title: 'Logic Area',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'PC.WMSBasic.StorageAreaSetting.ZoneCluster.View',
            title: 'Storage Area Setting',
          },
        ],
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/logic-area/index.vue'
        ),
    },
    {
      path: 'staginglocation',
      name: 'wmsbasic.staginglocation',
      meta: {
        title: 'Staging Location',
      },
      redirect: '/wmsbasic/staginglocation/arriving',
      component: () =>
        // import('@/views/inventory-management/inventory-strategy'),
        import('@/views/wms-basic/inbound-staging-location'),
      children: [
        {
          path: 'arriving',
          // name: 'PC.InventoryManagement.Replenishment.Strategy.ForecastSales.View',
          name: 'PC.WMSBasic.InboundStagingLocation.ArrivingStagingLocation',
          meta: {
            title: 'Arriving Staging Location',
            menuHide: true,
          },
          component: asyncLoadArrivingComponent,
        },
        {
          path: 'qc',
          name: 'PC.WMSBasic.InboundStagingLocation.QCStagingLocation',
          meta: {
            title: 'QC Staging Location',
            menuHide: true,
          },
          component: () =>
            import(
              '@/views/wms-basic/inbound-staging-location/qc-staging-location'
            ),
        },
        {
          path: 'putaway',
          name: 'PC.WMSBasic.InboundStagingLocation.PutawayStagingLocation',
          meta: {
            title: 'Putaway Staging Location',
            menuHide: true,
          },
          component: () =>
            import(
              '@/views/wms-basic/inbound-staging-location/putaway-staging-location'
            ),
        },
        {
          path: 'ibt',
          name: 'PC.WMSBasic.InboundStagingLocation.IBTStagingLocation',
          meta: {
            title: 'IBT Staging Location',
            menuHide: true,
          },
          component: () =>
            import(
              '@/views/wms-basic/inbound-staging-location/ibt-staging-location'
            ),
        },
        {
          path: 'diversionstaginglocation',
          name: 'PC.WMSBasic.DiversionStagingLocation.View',
          meta: {
            title: 'Diversion Staging Location',
            menuHide: true,
          },
          component: () =>
            import(
              '@/views/wms-basic/diversion-staging-location/diversion-staging-location/index.vue'
            ),
        },
        {
          path: 'disputestaginglocation',
          name: 'PC.WMSBasic.RIStagingLocation.DisputeStagingLocation',
          meta: {
            title: 'Dispute Staging Location',
            menuHide: true,
          },
          component: () =>
            import(
              '@/views/wms-basic/ri-staging-location/dispute-staging-location/index.vue'
            ),
        },
        {
          path: 'inboundParcelStagingLocation',
          name: 'PC.WMSBasic.RIStagingLocation.InboundParcelStagingLocation',
          meta: {
            title: 'Inbound Carton Staging Location',
            menuHide: true,
          },
          component: () =>
            import(
              '@/views/wms-basic/ri-staging-location/inbound-parcel-staging-location/index.vue'
            ),
        },
        {
          path: 'liquidatestaginglocation',
          name: 'PC.WMSBasic.RIStagingLocation.LiquidateStagingLocation',
          meta: {
            title: 'Liquidate Staging Location',
            menuHide: true,
          },
          component: () =>
            import(
              '@/views/wms-basic/ri-staging-location/liquidate-staging-location/index.vue'
            ),
        },
      ],
    },
    {
      path: 'supplier',
      name: 'wmsbasic.supplier',
      meta: {
        menuHide: false,
        filterSave: true,
        title: 'Supplier',
      },
      component: () => import('../../views/wms-basic/supplier/index.vue'),
    },
    {
      path: 'supplier/detail/:id',
      name: 'wmsbasic.supplier.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.supplier',
            title: 'Supplier',
          },
        ],
      },
      component: () => import('../../views/wms-basic/supplier/detail.vue'),
    },
    {
      path: 'shop',
      name: 'wmsbasic.shop',
      meta: {
        menuHide: false,
        title: 'Shop',
      },
      redirect: 'shop/shop',
      // component: () => import('../../views/wms-basic/shop/index.vue'),
    },
    {
      path: 'shop/shop',
      name: 'wmsbasic.shop.shop',
      meta: {
        menuHide: true,
        title: 'Shop',
      },
      component: () => import('../../views/wms-basic/shop/index.vue'),
    },
    {
      path: 'shop/group',
      name: 'wmsbasic.shop.group',
      meta: {
        menuHide: true,
        title: 'Shop Group',
        filterSave: true,
      },
      component: () => import('../../views/wms-basic/shop/index.vue'),
    },
    {
      path: 'shop/create',
      name: 'wmsbasic.shop.create',
      meta: {
        menuHide: true,
        title: 'Create',
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.shop',
            title: 'Shop',
          },
        ],
      },
      component: () => import('../../views/wms-basic/shop/detail.vue'),
    },
    {
      path: 'categoryTree',
      name: 'wmsbasic.categoryTree',
      meta: {
        title: 'Category Tree',
      },
      component: () => import('../../views/wms-basic/category-tree/index.vue'),
    },
    {
      path: 'skuitems',
      name: 'wmsbasic.skuItems',
      meta: {
        title: 'SKU Items',
        filterSave: true,
      },
      component: () => import('../../views/wms-basic/sku-items/index.vue'),
    },
    {
      path: 'skuAttributeCollection',
      name: 'wmsbasic.skuattributecollection',
      component: () => import('@/views/layout/subPage.vue'),
      meta: {
        title: 'SKU Attribute Collection',
        icon: '',
      },
      redirect: '/wmsbasic/skuAttributeCollection/attributeCollect',
      children: [
        {
          path: 'attributeCollect',
          name: 'wmsbasic.skuattributecollection.attributecollect',
          meta: {
            title: 'Attribute Collect',
          },
          component: () =>
            import(
              '../../views/inbound/new-sku-attributes-collection/index.vue'
            ),
        },
        {
          path: 'collectionTask',
          name: 'wmsbasic.skuattributecollection.collectiontask',
          meta: {
            title: 'Collection Task',
          },
          component: () =>
            import(
              '../../views/task-center/inbound-task/collecting-task/index.vue'
            ),
        },
      ],
    },
    {
      path: 'skuMappingTabs',
      name: 'wmsbasic.skuMapping',
      meta: {
        title: 'SKU Mapping',
      },
      redirect: '/wmsbasic/skuMapping',
      component: () => import('../../views/layout/subPage.vue'),
    },
    {
      path: 'skuMapping',
      name: 'PC.WMSBasic.SKUMapping.SKUMapping.View',
      meta: {
        title: 'SKU Mapping',
        menuHide: true,
      },
      component: () => import('../../views/wms-basic/sku-mapping/index.vue'),
    },
    {
      path: 'modelSkuMapping',
      name: 'PC.WMSBasic.SKUMapping.ModelSKUMapping.View',
      meta: {
        title: 'Model SKU Mapping',
        menuHide: true,
      },
      component: () => import('../../views/wms-basic/sku-mapping/index.vue'),
    },
    {
      path: 'skuitems/:sku_id',
      name: 'wmsbasic.skuItems.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.skuItems',
            title: 'SKU Items',
          },
        ],
      },
      component: () => import('../../views/wms-basic/sku-items/edit/index.vue'),
    },
    {
      path: 'skuitems/detail/:sku_id',
      name: 'wmsbasic.skuItems.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.skuItems',
            title: 'SKU Items',
          },
        ],
      },
      component: () => import('../../views/wms-basic/sku-items/detail.vue'),
    },
    {
      path: 'pathway',
      name: 'PC.WMSBasic.StorageAreaSetting.Pathway.view',
      meta: {
        title: 'Pathway',
        menuHide: true,
        filterSave: true,
        navList,
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/pathway/pathway.vue'
        ),
    },
    {
      path: 'pathway/edit',
      name: 'wmsbasic.pathway.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          ...navList,
          {
            routeName: 'PC.WMSBasic.StorageAreaSetting.Pathway.view',
            title: 'Pathway',
          },
        ],
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/pathway/pathwayEdit.vue'
        ),
    },
    {
      path: 'pathway/detail',
      name: 'wmsbasic.pathway.detail',
      meta: {
        title: 'Detail',
        filterSave: true,
        icon: '',
        menuHide: true,
        navList: [
          ...navList,
          {
            routeName: 'PC.WMSBasic.StorageAreaSetting.Pathway.view',
            title: 'Pathway',
          },
        ],
      },
      component: () =>
        import(
          '../../views/wms-basic/storage-area-setting/pathway/pathwayEdit.vue'
        ),
    },
    {
      path: 'cell',
      name: 'PC.WMSBasic.StorageAreaSetting.Cell.View',
      meta: {
        title: 'Cell',
        menuHide: true,
        filterSave: true,
        navList,
      },
      component: () =>
        import('../../views/wms-basic/storage-area-setting/cell/cell.vue'),
    },
    {
      path: 'cell/edit',
      name: 'wmsbasic.cell.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          ...navList,
          {
            routeName: 'PC.WMSBasic.StorageAreaSetting.Cell.View',
            title: 'Cell',
          },
        ],
      },
      component: () =>
        import('../../views/wms-basic/storage-area-setting/cell/cellEdit.vue'),
    },
    {
      path: 'cell/detail',
      name: 'wmsbasic.cell.detail',
      meta: {
        title: 'Detail',
        icon: '',
        menuHide: true,
        navList: [
          ...navList,
          {
            routeName: 'PC.WMSBasic.StorageAreaSetting.Cell.View',
            title: 'Cell',
          },
        ],
      },
      component: () =>
        import('../../views/wms-basic/storage-area-setting/cell/cellEdit.vue'),
    },
    {
      path: 'shop/detail/:id',
      name: 'wmsbasic.shop.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.shop',
            title: 'Shop',
          },
        ],
      },
      component: () => import('../../views/wms-basic/shop/detail.vue'),
    },
    {
      path: 'shop/:id/edit',
      name: 'wmsbasic.shop.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.shop',
            title: 'Shop',
          },
        ],
      },
      component: () => import('../../views/wms-basic/shop/detail.vue'),
    },
    // {
    //   path: 'zone',
    //   name: 'zone',
    //   meta: {
    //     menuHide: true,
    //     title: 'Zone Cluster',
    //   },
    //   component: () => import('../../views/wms-basic/zone.vue'),
    // },

    {
      path: 'dockmanagement',
      name: 'wmsbasic.dockManagement',
      meta: {
        title: 'Dock Management',
        filterSave: true,
      },
      component: () => import('../../views/wms-basic/dock-management/dock.vue'),
    },
    {
      path: 'dockmanagement/detail',
      name: 'wmsbasic.dockManagement.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.dockManagement',
            title: 'Dock Management',
          },
        ],
      },
      component: () =>
        import('../../views/wms-basic/dock-management/dock-detail.vue'),
    },
    {
      path: 'giftManagement',
      name: 'wmsbasic.giftManagement',
      meta: {
        title: 'Gift Management',
      },
      component: () => import('../../views/wms-basic/gift-management/gift.vue'),
    },
    {
      path: 'giftManagement/detail/:id',
      name: 'wmsbasic.giftManagement.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.giftManagement',
            title: 'Gift Management',
          },
        ],
      },
      component: () =>
        import('../../views/wms-basic/gift-management/gift-detail.vue'),
    },
    {
      path: 'giftManagement/create',
      name: 'wmsbasic.giftManagement.create',
      meta: {
        title: 'Create',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.giftManagement',
            title: 'Gift Management',
          },
        ],
      },
      component: () => import('../../views/wms-basic/gift-management/edit.vue'),
    },
    {
      path: 'giftManagement/edit/:id',
      name: 'wmsbasic.giftManagement.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.giftManagement',
            title: 'Gift Management',
          },
        ],
      },
      component: () => import('../../views/wms-basic/gift-management/edit.vue'),
    },
    {
      path: 'stationManagementContainer',
      name: 'wmsbasic.stationManagement',
      meta: {
        title: 'Station Management',
      },
      redirect: '/wmsbasic/stationManagement',
    },
    {
      path: 'stationManagement',
      name: 'PC.WMSBasic.StationManagement.StationManagement.View',
      meta: {
        title: 'Station Management',
        menuHide: true,
      },
      component: () => import('@/views/wms-basic/station-management/index.vue'),
    },
    {
      path: 'recordSettings',
      name: 'PC.WMSBasic.StationManagement.RecordSettings.View',
      meta: {
        title: 'Record Settings',
        menuHide: true,
      },
      component: () => import('@/views/wms-basic/station-management/index.vue'),
    },
    {
      path: 'merchant',
      name: 'wmsbasic.merchant',
      meta: {
        title: 'Merchant',
      },
      component: () => import('../../views/wms-basic/merchant/index.vue'),
    },
    {
      path: 'merchant/detail/:merchant_id',
      name: 'wmsbasic.merchant.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'wmsbasic',
            title: 'WMS Basic',
          },
          {
            routeName: 'wmsbasic.merchant',
            title: 'Merchant',
          },
        ],
      },
      component: () => import('../../views/wms-basic/merchant/detail.vue'),
    },
  ],
};
async function asyncLoadArrivingComponent(): Promise<AsyncComponent> {
  return import(
    `@/views/wms-basic/inbound-staging-location/arriving-staging-location${
      store.state.system.enableFulfilChain ? '-new' : ''
    }`
  ) as unknown as AsyncComponent;
}

function getAccessStorageTabs(): Tab[] {
  return tabs.filter(
    (tab) =>
      hasPermission(tab.permissionName) &&
      !(
        tab.url === RouterName.StorageAreaSetting.WHSPlanning.name &&
        !store.state.system.enableFulfilChain
      ) // 当履约线开关关闭时，过滤掉 WHS Planning 标签
  );
}

function getFirstStorageTab(): string {
  return getAccessStorageTabs()[0]?.path ?? '/wmsbasic/zonecluster';
}

export default wmsBasic;
